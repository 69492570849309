import { getParam, stringCell } from "./utils";

export const debugGeneral = (args: string): Excel.StringCellValue | null => {
  const debugType = getParam(args, "debug");

  switch (debugType) {
    case "basic":
      return stringCell("Hello from Debug!");
    case "products":
      return stringCell(global.Sparta.dumpProducts());
    case "user":
      return stringCell(global.Sparta.dumpUser());
    case "queue":
      return stringCell(global.Sparta.dumpQueue());
    case "subs":
      return stringCell(global.Sparta.dumpSubscriptions());
    case "connection":
      return stringCell(global.Sparta.isConnected().toString());
    default:
      return null;
  }
};
