import { datadogRum } from "@datadog/browser-rum";
import { SpartaAPI } from "classes/SpartaAPI";
import { Subscription } from "classes/Subscription";
import type { Metadata } from "classes/types";

import { debugGeneral } from "./debug";
import { deserializePeriod, deserializeProductInfo, getProductCode, numberCell, stringCell } from "./utils";

/**
 * Displays the requested product Property
 * @customfunction
 * @streaming
 * @param args Custom arguments
 * @param productInfo Searialized info of the product.
 * @param property Product property to show
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function getProductMetadata(
  args: string,
  productInfo: string,
  property: string,
  invocation: CustomFunctions.StreamingInvocation<Excel.StringCellValue[][]>
) {
  try {
    const debugReturn = debugGeneral(args);

    if (debugReturn !== null) {
      return invocation.setResult([[debugReturn]]);
    }

    const metadataSubscription = global.Sparta.metadataSubject.subscribe(({ value: metadata }) => {
      if (!Object.keys(metadata).length) return invocation.setResult([[stringCell()]]);

      const productCode = getProductCode(productInfo, metadata);

      if (!productCode) {
        datadogRum.addError("[getProductMetadata] Product not found", {
          args: {
            product: productInfo,
            property,
          },
          productCode,
        });

        return invocation.setResult([[stringCell()]]);
      }

      const productMetadata = metadata[productCode];

      if (!productMetadata || Object.hasOwnProperty.call(productMetadata, property) === false) {
        datadogRum.addError("[getProductMetadata] Metadata not found", {
          args: {
            product: productInfo,
            property,
          },
          productCode,
          property,
        });

        return invocation.setResult([[stringCell()]]);
      }

      const observableProperties = ["name", "units", "type", "priceType"] satisfies (keyof Metadata)[];
      const castedProperty = property as (typeof observableProperties)[number];

      const isObservable = observableProperties.includes(castedProperty);

      if (!isObservable) {
        datadogRum.addError(`[getProductMetadata] Property is not observable`, {
          args: {
            product: productInfo,
            property,
          },
          productCode,
          property,
        });

        return invocation.setResult([[stringCell()]]);
      }

      return invocation.setResult([[stringCell(productMetadata?.[castedProperty])]]);
    });

    invocation.onCanceled = () => {
      metadataSubscription?.unsubscribe();
    };
  } catch (error) {
    datadogRum.addError("[getProductMetadata] Unexpected error", { error });

    invocation.setResult([[stringCell()]]);
  }
}

/**
 * Displays the last value for a symbol
 * @customfunction
 * @streaming
 * @param args Custom arguments
 * @param productInfo Searialized info of the product.
 * @param period Searialized list of TENOR and DATE.
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function observeLiveCurve(
  args: string,
  productInfo: string,
  period: string,
  invocation: CustomFunctions.StreamingInvocation<Excel.CellValue[][]>
) {
  try {
    const debugReturn = debugGeneral(args);

    if (debugReturn !== null) {
      return invocation.setResult([[debugReturn]]);
    }

    let productSubscription: Subscription<string | number | null> | undefined = undefined;
    const metadataSubscription = global.Sparta.metadataSubject.subscribe(({ value: metadata }) => {
      if (!Object.keys(metadata).length) return invocation.setResult([[stringCell()]]);

      let productCode = getProductCode(productInfo, metadata);

      if (!productCode) {
        const { DATA_PROVIDER, NAME } = deserializeProductInfo(productInfo);

        // Data provider is not present in the product info, so we need to infer the product only with the name
        if (!DATA_PROVIDER && NAME && global.Sparta.isObEnabled) {
          const products = Object.entries(SpartaAPI.getProductsByLabel(NAME, metadata));

          // Priority to DP_1
          productCode = products.find(([, { dataProvider }]) => dataProvider === "DP_1")?.[0] || products[0][0];
        }
      }

      const [, tenorName] = deserializePeriod(period);

      if (!productCode || !tenorName) {
        datadogRum.addError("[observeLiveCurve] Product or Tenor not found", {
          args: {
            product: productInfo,
            period,
          },
          productCode,
          tenorName,
        });

        return invocation.setResult([[stringCell()]]);
      }

      const productMetadata = metadata[productCode];

      if (!productMetadata) {
        datadogRum.addError("[observeLiveCurve] Metadata not found", {
          args: {
            product: productInfo,
            period,
          },
          productCode,
        });

        return invocation.setResult([[stringCell()]]);
      }

      productSubscription?.unsubscribe(); // Unsubscribe the previous execution
      productSubscription = global.Sparta.busForProduct(productCode, tenorName).subscribe(({ value: price }) => {
        if (price === null) {
          return invocation.setResult([[stringCell()]]);
        }

        if (productMetadata.priceType === "Datetime") {
          return invocation.setResult([[stringCell(price as string)]]);
        } else {
          return invocation.setResult([[numberCell(price as number, productMetadata.decimalPlaces)]]);
        }
      });
    });

    invocation.onCanceled = () => {
      metadataSubscription.unsubscribe();
      productSubscription?.unsubscribe();
    };
  } catch (error) {
    datadogRum.addError("[observeLiveCurve] Unexpected error", { error });

    invocation.setResult([[stringCell()]]);
  }
}

/**
 * Displays month and day.
 * @customfunction
 * @param args Custom arguments
 * @param period Searialized list of TENOR and DATE.
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function getPeriod(args: string, period: string): Excel.StringCellValue {
  try {
    const debugReturn = debugGeneral(args);

    if (debugReturn !== null) {
      return debugReturn;
    }

    const [, tenorName] = deserializePeriod(period);

    return stringCell(tenorName);
  } catch (error) {
    datadogRum.addError("[getPeriod] Unexpected error", { error });

    return stringCell();
  }
}

CustomFunctions.associate("GETPRODUCTMETADATA", getProductMetadata);
CustomFunctions.associate("OBSERVELIVECURVE", observeLiveCurve);
CustomFunctions.associate("GETPERIOD", getPeriod);